@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff") format("woff");
}

@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff") format("woff");
}

@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff") format("woff");
}

@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff") format("woff");
}

@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff") format("woff");
}

@font-face {
    font-family: Avenir Next;
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff2")
    format("woff2"),
    url("/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff") format("woff");
}
